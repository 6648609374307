<template>
  <div class="digi_uploader">
    <el-upload
      ref="upload"
      :http-request="uploads"
      class="upload-demo"
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :before-upload="beforeFileUpload"
      :limit="1"
      :file-list="fileLists"
      :disabled="publicdisabled"
      multiple
    >
      <el-button size="small" type="primary" icon="el-icon-plus"
        >点击上传</el-button
      >
      <div slot="tip">
        只能上传pdf/png/jpg/jpeg类型的附件，且不超过10Mb
      </div>
    </el-upload>
    <!-- <el-dialog
      class="system-modal editDialog"
      title="正在进行上传"
      :visible.sync="isFinally"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percent"
      ></el-progress>
      <p>正在上传...</p>
    </el-dialog> -->
  </div>
</template>

<script>
import { upload } from "@/api/upload";
export default {
  name: "DigiUploader",
  data() {
    return {
      fileList: [],
      fileLists: [],
      publicdisabled: false,
      // isFinally: false,
      percent: 0,
    };
  },
  methods: {
    async uploads(args) {
      // this.isFinally = true;
      this.percent = 0;
      let res = await upload(args.file, (e) => {
        this.percent = ((e.loaded / e.total) * 100) | 0;
        return args.onProgress({ percent: this.percent });
      });
      if (res.code == 0) {
        this.$emit("uploader", res.data.uri);
        this.$message.success("上传成功");
        // return this.percent;
      } else {
        this.message.error("上传失败");
        this.$emit("error", res.message);
      }
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList;
      this.$emit("uploader", fileList);
    },
    beforeFileUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension4 = testmsg === "png";
      let extension5 = testmsg === "jpg";
      let extension6 = testmsg === "jpeg";
      let extension8 = testmsg === "pdf";
      if (!extension4 && !extension5 && !extension6 && !extension8) {
        this.$message.error("上传附件只能是png/jpg/jpeg/pdf 格式!");
        return false;
      }
      let isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传大小不能超过 10MB!");
        return false;
      }
    },
    handlePreview(file) {
      console.log(file);
      upload(file.raw).then((res) => {
        console.log(res);
        if (res.code == 0) {
          window.open(
            "https://view.xdocin.com/view?src=" +
              encodeURIComponent(res.data.uri)
          );
        }
      });
      console.log(1112323);
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
    },
  },
};
</script>

<style></style>
